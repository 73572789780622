// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyC_zV6s7FrJcfmQZ1SUpTouS76KMfW1FLw',
    authDomain: 'rearpassenger.firebaseapp.com',
    databaseURL: 'https://rearpassenger.firebaseio.com',
    projectId: 'rearpassenger',
    storageBucket: 'rearpassenger.appspot.com',
    messagingSenderId: '1049463473249',
    appId: '1:1049463473249:web:cd0ff3541bb1222d565620',
    measurementId: 'G-R6ZNGE8MDS'
  }
};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
