import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { HttpReturnValue } from 'src/app/shared/interfaces/http-return-value';
import { SeatInfo } from 'src/app/shared/interfaces/seat-info';
import { VehicleInfo } from 'src/app/shared/interfaces/vehicle-info';
import { ErrorInfo } from 'src/app/shared/interfaces/error-info';

@Injectable()
export class HttpfunctionsService {

  private baseURL = 'https://us-central1-rearpassenger.cloudfunctions.net/rest/';

  constructor(private http: HttpClient) { }

  getVehicleInfo(vehicleId: string): Observable<VehicleInfo> {
    const url = this.baseURL + 'getVehicleInfo?vehicleId=' + vehicleId;

    return this.http.get<HttpReturnValue>(url).pipe(
      map(response => response.data as VehicleInfo),
      catchError(this.errorHandler)
    );
  }

  setSeatState(vehicleId: string, seatInfo: SeatInfo): Observable<VehicleInfo> {

    /*const token = 'ef6cfab503a3bcdca7490be8ea26c44240c66f5816817674ab9d55ea62cf7858';
    const id = '64893261405432383';

    const teslaUrl = 'https://owner-api.teslamotors.com/api/1/vehicles/' + id + '/data_request/climate_state';

    const teslaOpts = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token
      })
    };

    return this.http.get(teslaUrl, teslaOpts).pipe(
      map(response => {
        console.log(response);
        return response as VehicleInfo;
      }),
      catchError(this.errorHandler)
    );*/



    const url = this.baseURL + 'setSeatState';

    const body = {
      vehicleId,
      seatInfo
    };

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    return this.http.post<HttpReturnValue>(url, body, options).pipe(
      map(response => response.data as VehicleInfo),
      catchError(this.errorHandler)
    );
  }

  errorHandler(httpError: HttpErrorResponse) {
    if (httpError.status === 404) {
      const error: ErrorInfo = {
        error: 'Unable to contact server',
        errorCode: 'COMMUNICATIONS_ERROR'
      };

      return throwError(error);
    }

    return throwError(httpError.error.data);
  }
}
