import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { HttpfunctionsService } from './httpfunctions.service';



@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    HttpClient,
    HttpfunctionsService
  ],
  declarations: [
  ],
  exports: [
  ]
})
export class CoreModule { }
